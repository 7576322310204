import React, {Component} from 'react';
import ButtonRedirect from "../hooks/ButtonRedirect";
import {Dialog, DialogContent, DialogTitle, TextField, Tooltip} from "@mui/material";
import Axios from "axios";
import Swal from "sweetalert2";
import {sha256} from "js-sha256";
const appVersion = require('../../package.json').version;
const baseUrl = "tarkovshoppinglist.com/back";

const Toast = Swal.mixin({
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 6000,
    timerProgressBar: true,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})

let itemsJson = []



class DefaultPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsGunsmith:[],
            myHideout:[],
            toSearch:"",
            userConnected:null,
            displayConnectionDialog:false,
            displayInscriptionDialog:false,
            displayDone:true,
            displayOnlyFir:false,
            displayOnlyNor:false,
            displayMode:"normal",
            displayAllGunsmith:true,
            allGunsmithPartSelected:[],
            showBrowserView:true,
            hideout_items:[],
            quests_items:[],
            filterTrader:"prapor"
        };
    }

    resizePage = () => {
        if(document.body.offsetWidth <= 1200 && this.state.showBrowserView === true){
            this.setState({showBrowserView:false})
        }else if(document.body.offsetWidth > 1200 && this.state.showBrowserView === false){
            this.setState({showBrowserView:true})
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resizePage)
    }

    componentDidMount() {
        window.addEventListener('resize', this.resizePage)
        if(localStorage.getItem("user")){
            this.connectUser("manual",{target:[{value:localStorage.getItem("user")},{value:localStorage.getItem("password")}]})
        }
    }

    getUserData(user){
        Axios.post("https://"+baseUrl+"/getJsonData", {user}).then(res => {
            itemsJson = res.data
            this.setState({items:res.data.items})
        })
    }
    searchItems(event){
        event.preventDefault()
        let toSearch = event.target[0].value === this.state.toSearch ? "" : event.target[0].value
        if(event.target[0].value === this.state.toSearch){
            let inputResearch = document.getElementsByClassName("inputResearch")
            inputResearch[0].value = ""
        }
        this.setState({toSearch})
    }

    updateItemsJson(itemName,action,type){
        let itemsTemp = this.state.items
        itemsTemp.forEach((item,index)=>{
            if(item.name === itemName){
                if(type === "FIR"){
                    if(action === "+"){
                        item.myInvFir++
                    }else{
                        item.myInvFir--
                    }
                }else{
                    if(action === "+"){
                        item.myInvNotFir++
                    }else{
                        item.myInvNotFir--
                    }
                }
            }
        })
        this.setState({items:itemsTemp})
        let dataToSend = {items:itemsTemp}
        Axios.post("https://"+baseUrl+"/updateItemsJson", {data: dataToSend, user:this.state.userConnected})
    }

    updateGunsmithJson(item,action){
        let itemsGunsmithTemp = this.state.itemsGunsmith
        itemsGunsmithTemp.forEach((elt,index)=>{
            if(elt.shortname === item.shortname){
                if(action === "+"){
                    /*On push le plus petit des part qui n'est pas déjà dans myInventorybyParts*/
                    let partToAdd = 0
                    for(let i = 0; i < item.part.length; i++){
                        item.part = item.part.sort((a,b)=>a-b)
                        if(!elt.myInventorybyParts.includes(item.part[i])){
                            partToAdd = item.part[i]
                            break
                        }
                    }
                    elt.myInventorybyParts.push(partToAdd)
                }else{
                    /*On splice le plus grand des part qui est dans myInventorybyParts*/
                    let partToRemove = 0
                    for(let i = item.part.length-1; i >= 0; i--){
                        item.part = item.part.sort((a,b)=>a-b)
                        if(elt.myInventorybyParts.includes(item.part[i])){
                            partToRemove = item.part[i]
                            break
                        }
                    }
                    elt.myInventorybyParts.splice(elt.myInventorybyParts.indexOf(partToRemove),1)
                }
            }
        })
        this.setState({itemsGunsmith:itemsGunsmithTemp})
        let dataToSend = {gunsmith:itemsGunsmithTemp}
        Axios.post("https://"+baseUrl+"/updateGunsmithJson", {data: dataToSend, user:this.state.userConnected})

    }

    signUpUser(event){
        event.preventDefault()
        if(event.target[1].value === event.target[2].value){
            Axios.post("https://"+baseUrl+"/signUpUser", {data: {username:event.target[0].value,password:sha256(event.target[1].value)}}).then(res => {
                this.setState({displayInscriptionDialog:false, userConnected:event.target[0].value,items:res.data.items, myHideout:res.data.myHideout,itemsGunsmith:res.data.gunsmith,myQuests:res.data.myQuests})
                itemsJson = res.data
                Toast.fire({
                    title: "Welcome "+event.target[0].value+" !",
                    icon: 'success',
                })
                this.getQuestsAndHideoutJson()
            }).catch((err)=>{
                this.setState({displayInscriptionDialog:false})
                Swal.fire({
                    title: 'Error!',
                    text: err.message.includes("409") ? "Username already in use..." : err.message,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                })
            })
        }else{
            alert("Passwords are not the same !")
        }
    }

    connectUser(type,event){
        if(type !== "manual"){
            event.preventDefault()
        }
        Axios.post("https://"+baseUrl+"/connectUser", {data: {username:event.target[0].value,password:sha256(event.target[1].value)}}).then(res => {
            this.setState({displayConnectionDialog:false, userConnected:event.target[0].value,items:res.data.items, myHideout:res.data.myHideout,itemsGunsmith:res.data.gunsmith,myQuests:res.data.myQuests})
            itemsJson = res.data
            localStorage.setItem("user",event.target[0].value)
            localStorage.setItem("password",event.target[1].value)
            Toast.fire({
                title: "Welcome back "+event.target[0].value+" !",
                icon: 'success',
            })
            this.getQuestsAndHideoutJson()
        }).catch((err)=>{
            this.setState({displayConnectionDialog:false,userConnected:null, items:[]})
            localStorage.removeItem("user")
            localStorage.removeItem("password")
            console.log(err.message)
            Swal.fire({
                title: 'Error!',
                text: err.message.includes("401") ? "Wrong username or password..." : err.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })
        })
    }

    getQuestsAndHideoutJson(){
        Axios.post("https://"+baseUrl+"/getQuestsAndHideoutJson").then(res => {
            this.setState({quests_items:res.data.quests_items, hideout_items:res.data.hideout_items})
        })
    }

    disconnectUser(){
        this.setState({userConnected:null, items:[]})
        localStorage.removeItem("user")
        localStorage.removeItem("password")
        Toast.fire({
            title: "See you soon !",
            icon: 'success',
        })
    }

    gunsmithPartManager(part){
        let allGunsmithPartSelected = this.state.allGunsmithPartSelected
        if(allGunsmithPartSelected.includes(part)){
            allGunsmithPartSelected.splice(allGunsmithPartSelected.indexOf(part),1)
        }else{
            allGunsmithPartSelected.push(part)
        }
        this.setState({
            [part+"StateGun"]:!this.state[part+"StateGun"],
            allGunsmithPartSelected
        })
    }

    updateHideoutTier(station,level, myLevel){
        if(myLevel !== level){
            let myHideoutTemp = this.state.myHideout
            let index = myHideoutTemp.findIndex((elt)=>elt.name === station.name)

            myHideoutTemp[index].level = level
            this.setState({myHideout:myHideoutTemp})
            let dataToSend = {myHideout:myHideoutTemp}
            Axios.post("https://"+baseUrl+"/updateHideoutJson", {data: dataToSend, user:this.state.userConnected}).then(res => {
                let itemsTemp = this.state.items
                // En cas d'augmentation de tier :
                if(myLevel < level){
                    station.tier.forEach((elt)=>{
                        if(elt.level > myLevel && elt.level <= level){
                            elt.items_used.forEach((item)=>{
                                /*modify item in itemsTemp at right index*/
                                let index = itemsTemp.findIndex((elt)=>elt.name === item.item)
                                if(itemsTemp[index].myInvNotFir + item.amount > itemsTemp[index].notfir){
                                    itemsTemp[index].myInvNotFir = itemsTemp[index].notfir
                                }else{
                                    itemsTemp[index].myInvNotFir += item.amount
                                }
                            })
                        }
                    })
                }else{
                    station.tier.forEach((elt)=>{
                        if(elt.level <= myLevel && elt.level > level){
                            elt.items_used.forEach((item)=>{
                                /*modify item in itemsTemp at right index*/
                                let index = itemsTemp.findIndex((elt)=>elt.name === item.item)
                                if(itemsTemp[index].myInvNotFir - item.amount < 0){
                                    itemsTemp[index].myInvNotFir = 0
                                }else{
                                    itemsTemp[index].myInvNotFir -= item.amount
                                }
                            })
                        }
                    })
                }
                this.setState({items:itemsTemp})
                let dataToSend = {items:itemsTemp}
                Axios.post("https://"+baseUrl+"/updateItemsJson", {data: dataToSend, user:this.state.userConnected})
                Toast.fire({
                    title: "Hideout updated !",
                    icon: 'success',
                })
            })
        }
    }

    changeQuestCompleted(quest){
        let myQuestsTemp = this.state.myQuests
        let allQuestsTemp = this.state.quests_items

        let index = myQuestsTemp.findIndex((elt)=>elt.name === quest.name)
        myQuestsTemp[index].done = !myQuestsTemp[index].done
        this.setState({myQuests:myQuestsTemp})
        Axios.post("https://"+baseUrl+"/updateQuestsJson", {data: {myQuests:myQuestsTemp}, user:this.state.userConnected}).then(res => {
            let itemsTemp = this.state.items
            //Si on valide la quête ou qu'on annule la quete, on modifie les items de la même manière
            quest.items_to_give.forEach((item)=>{
                /*modify item in itemsTemp at right index*/
                let index = itemsTemp.findIndex((elt)=>elt.name === item.name)
                let allItemsDoneByQuestNOR = 0
                let allItemsDoneByQuestFIR = 0
                allQuestsTemp.forEach((elt,indexAllQuest)=>{ //On vérifie toutes les quetes qui influent cet item
                    if(myQuestsTemp[indexAllQuest].done && elt.items_to_give.findIndex((elt)=>elt.name === itemsTemp[index].name) !== -1){ //Si la quete est validée et qu'elle donne l'item
                        if (elt.items_to_give[elt.items_to_give.findIndex((elt)=>elt.name === itemsTemp[index].name)].fir === true){
                            allItemsDoneByQuestFIR += elt.items_to_give[elt.items_to_give.findIndex((elt)=>elt.name === itemsTemp[index].name)].count //On ajoute le nombre d'item donné par la quete
                        }else{
                            allItemsDoneByQuestNOR += elt.items_to_give[elt.items_to_give.findIndex((elt)=>elt.name === itemsTemp[index].name)].count //On ajoute le nombre d'item donné par la quete
                        }
                    }
                })
                if(item.fir){
                    // if(itemsTemp[index].myInvFir + item.count > itemsTemp[index].fir){
                    //     itemsTemp[index].myInvFir = itemsTemp[index].fir
                    // }else{
                    //
                    if(item.name === "roler"){
                        itemsTemp[index].myInvFir += item.count
                    }else{
                        itemsTemp[index].myInvFir = allItemsDoneByQuestFIR //On met à jour le nombre d'item dans l'inventaire
                    }
                    // }
                }else{
                    // if(itemsTemp[index].myInvNotFir + item.count > itemsTemp[index].notfir){
                    //     itemsTemp[index].myInvNotFir = itemsTemp[index].notfir
                    // }else{
                    if(item.name === "tset" || item.name === "goldchain" || item.name === "camera" || item.name === "multitool" || item.name === "roler"){
                        itemsTemp[index].myInvNotFir += item.count
                    }else{
                        itemsTemp[index].myInvNotFir = allItemsDoneByQuestNOR
                    }
                    // }
                }
            })
            this.setState({items:itemsTemp})
            let dataToSend = {items:itemsTemp}
            Axios.post("https://"+baseUrl+"/updateItemsJson", {data: dataToSend, user:this.state.userConnected})
            Toast.fire({
                title: "Quest updated !",
                icon: 'success',
            })
        })


    }

    render() {
        return (
            <div style={{height:"calc(100vh)",width:"100vw",display:"flex",backgroundColor:"#232323",justifyContent:"center",alignItems:"center", overflowX:"hidden",flexDirection:"column"}}>
                <Dialog
                    open={this.state.displayConnectionDialog}
                    onClose={()=>{this.setState({displayConnectionDialog: false})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                >
                    <DialogTitle>
                        <h1 className={"benderBold"} style={{marginTop:0, marginBottom:5}}>Log in</h1>
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{padding:0,paddingLeft:8,paddingRight:8, flexDirection:"column"}}>
                        <form onSubmit={(e)=>{this.connectUser("automatic",e)}}>

                            <input required className={"inputText bender"} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"text"} placeholder={"Username"}/>
                            <input required className={"inputText bender"} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"password"} placeholder={"Password"}/>
                            <div className={"flexCenter"} style={{width:"100%"}}>
                                <button type={"submit"} className={"betterButton bender"} style={{border:"1px solid #232323FF", color:"#232323FF", borderRadius:10, fontSize:24, margin:10}}>Go</button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayInscriptionDialog}
                    onClose={()=>{this.setState({displayInscriptionDialog: false})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                >
                    <DialogTitle>
                        <h1 className={"benderBold"} style={{marginTop:0, marginBottom:5}}>Sign up</h1>
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{padding:0,paddingLeft:8,paddingRight:8, flexDirection:"column"}}>
                        <form onSubmit={(e)=>{this.signUpUser(e)}}>
                            <input required className={"inputText bender"} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"text"} placeholder={"Username"}></input>
                            <p style={{margin:0, fontWeight:"100"}}>(Your password is protected by a sha256 algorithm)</p>
                            <input required className={"inputText bender"} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"password"} placeholder={"Password"}></input>
                            <input required className={"inputText bender"} style={{border:"1px solid #232323FF", color:"#232323FF"}} type={"password"} placeholder={"Password verification"}></input>
                            <div className={"flexCenter"} style={{width:"100%"}}>
                                <button type={"submit"} className={"betterButton bender"} style={{border:"1px solid #232323FF", color:"#232323FF", borderRadius:10, fontSize:24, margin:10}}>Go</button>
                            </div>
                        </form>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayWIT}
                    onClose={()=>{this.setState({displayWIT: false})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                >
                    <DialogTitle>
                        <h1 className={"benderBold"} style={{marginTop:0, marginBottom:5}}>What is this ?</h1>
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{padding:0,paddingLeft:15,paddingRight:15, paddingBottom:15, flexDirection:"column"}}>
                        <h2 className={"bender"} style={{marginTop:0, marginBottom:5}}> - Tarkov Shopping List will allow you to keep track of useful items that you encounter throughout your raids</h2>
                        <h2 className={"bender"} style={{marginTop:10, marginBottom:5}}> - Each item in this list is needed in the hideout, in quests, or both. </h2>
                        <h2 className={"bender"} style={{marginTop:10, marginBottom:5}}> - FIR stands for "found in raid" & NOR represents normal items. </h2>
                        <h2 className={"bender"} style={{marginTop:10, marginBottom:5}}> - All data is taken from <a target={"_blank"} href="https://escapefromtarkov.fandom.com/wiki/Escape_from_Tarkov_Wiki">the Official Escape from Tarkov Wiki</a>. </h2>
                        <h3 className={"bender"} style={{marginTop:10, marginBottom:5}}> Made by <span style={{fontStyle:"italic"}} className={"benderBold"}>Ekuix</span></h3>
                        {/*<p className={"bender"} style={{marginTop:0, marginBottom:5}}> (Special thanks to <span style={{fontStyle:"italic"}}>Furty</span>)</p>*/}

                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayHideoutChecker}
                    onClose={()=>{this.setState({displayHideoutChecker: false})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                >
                    <DialogTitle style={{backgroundColor:"#232323"}}>
                        <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start"}}>
                            <h1 className={"benderBold"} style={{marginTop:0, marginBottom:0, color:"#FFF"}}>Hideout Checker</h1>
                            <p className={"bender flexCenter"} style={{marginTop:0, marginBottom:5, color:"red", fontSize:18}}>
                                It may overwrite your manual inputs on normal items!
                                <Tooltip title={"If you manually added items to your list by clicking the + button, normal item used in a the construction may be overwritten."}>
                                    <img style={{width:20, opacity:0.8, marginLeft:5}} src={"./assets/infoIconRed.PNG"} alt="infoIcon"/>
                                </Tooltip>
                            </p>
                        </div>
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{padding:0,paddingLeft:8,paddingRight:8, alignItems:"flex-start",backgroundColor:"#232323"}}>
                        <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start"}}>
                            <div style={{maxWidth:"80vw",width:"fit-content",display:"flex", justifyContent:"center", alignItems:"normal", flexWrap:"wrap"}}>
                                {this.state.hideout_items.map((station, index) => (
                                    <div className={"flexCenter"} style={{flexDirection:"column", justifyContent:"flex-start", border:"1px solid grey", borderRadius:10, margin:10, padding:10, boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                                        <div className={"flexCenter"}>
                                            <div disabled={true} className={"hideoutButton"} style={{backgroundImage:`url(../assets/${station.name}.PNG)`,backgroundRepeat: "no-repeat",backgroundPosition:"center"}}/>
                                            <h3 className={"benderBold"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>{station.name.replaceAll("_"," ")}</h3>
                                        </div>
                                        <div className={"flexCenter"} style={{flexDirection:"column"}}>
                                            {(station.tier).map((tierlevel)=>(
                                                <button onClick={()=>{this.updateHideoutTier(station, tierlevel.level, this.state.myHideout[index].level)}} className={"betterButton"} style={{color:this.state.myHideout[index].level === tierlevel.level ? "green":"#FFF", border:this.state.myHideout[index].level === tierlevel.level && "1px solid green", borderRadius:10}}><h3 style={{margin:3}} className={"bender"}>
                                                    {(tierlevel.level === 0 ) ? (
                                                        <>
                                                            {station.default_tier_level === tierlevel.level && (
                                                                <>
                                                                   Not built
                                                                </>
                                                            )}
                                                        </>
                                                    ):(
                                                        <>
                                                            Tier {tierlevel.level}
                                                        </>
                                                    )}
                                                </h3></button>
                                            ))}
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={this.state.displayQuestsChecker}
                    onClose={()=>{this.setState({displayQuestsChecker: false})}} //Dans le callback pour bien être sûr de fermer le dialog avant de faire disparaitre l'image
                >
                    <DialogTitle style={{backgroundColor:"#232323"}}>
                        <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start"}}>
                            <h1 className={"benderBold"} style={{marginTop:0, marginBottom:0, color:"#FFF"}}>Quests Checker</h1>
                            <p className={"bender flexCenter"} style={{marginTop:0, marginBottom:5, color:"red", fontSize:18}}>
                                It may overwrite your manual inputs!
                                <Tooltip title={"If you manually added items to your list by clicking the + button, it may overwrite this number."}>
                                    <img style={{width:20, opacity:0.8, marginLeft:5}} src={"./assets/infoIconRed.PNG"} alt="infoIcon"/>
                                </Tooltip>
                            </p>
                        </div>
                    </DialogTitle>
                    <DialogContent className={"flexCenter"} style={{padding:0,paddingLeft:8,paddingRight:8, justifyContent:"flex-start",backgroundColor:"#232323", flexDirection:"column"}}>
                        <div className={"flexCenter"} style={{flexWrap:"wrap"}}>
                            {["prapor","therapist","skier","peacekeeper","mechanic","ragman","jaeger"].map(trader=>(
                                <div style={{margin:3, border:this.state.filterTrader === trader ? "2px solid #FFFFFF":"2px solid #232323", cursor:"pointer"}}>
                                    <img onClick={()=>{this.setState({filterTrader:this.state.filterTrader === trader ? "none":trader})}}  src={`../assets/${trader}.PNG`} alt={trader}/>
                                </div>
                            ))}

                        </div>
                        <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start"}}>
                            <div style={{maxWidth:"80vw",width:"fit-content",display:"flex", justifyContent:"center", alignItems:"normal", flexWrap:"wrap"}}>
                                {this.state.quests_items.map((quest, index) => (
                                    <div onClick={()=>{this.changeQuestCompleted(quest)}} className={"flexCenter betterButton"} style={{display:(this.state.filterTrader !== "none" && this.state.filterTrader !== quest.trader.toLowerCase()) && "none",flexDirection:"column", justifyContent:"flex-start", border:this.state.myQuests[index].done === false ? "1px solid grey":"1px solid green" , borderRadius:10, margin:10, padding:10, boxShadow:"rgba(0, 0, 0, 0.24) 0px 3px 8px"}}>
                                        <div className={"flexCenter"}>
                                            <h3 className={"benderBold"} style={{color:this.state.myQuests[index].done === false ? "#FFF":"green", margin:0, marginLeft:10, marginRight:10}}>{quest.name}</h3>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </DialogContent>
                </Dialog>
                <div className={"flexCenter"} style={{width:"100%", height:"fit-content",flexDirection:"column",boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}}>
                    <div style={{width:"100%",height:150, display:"flex", justifyContent:"space-between",alignItems:"center"}}>
                        <div className={"flexCenter"} style={{flexDirection:"column", alignItems:"flex-start", display:!this.state.showBrowserView && "none"}}>
                            <h1 className={"benderBold"} style={{color:"#FFF", fontWeight:100, fontSize:65, margin:0, marginLeft:15, whiteSpace:"nowrap"}}>Tarkov Shopping List<span style={{fontSize:15, color:"#888888", marginLeft:5}}>Alpha v{appVersion}</span></h1>
                            <h1 className={"bender"} style={{color:"#FFF", fontWeight:100, fontSize:20, margin:0, marginLeft:20}}>A tool to help keep track of useful items in Escape from Tarkov</h1>
                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                <button onClick={()=>{window.open("https://discord.gg/9BR4HqJ")}} style={{borderRadius:10, border:"1px solid #5865F2", color:"#5865F2", marginLeft:20, marginTop:5}} className={"betterButton flexCenter bender"}>
                                    Join our discord
                                    <img style={{width:20, marginLeft:5}} src={"./assets/discordLogo.PNG"} alt="discordLogo"/>
                                </button>
                                <button onClick={()=>{this.setState({displayWIT:true})}} style={{borderRadius:10, border:"1px solid #FFFFFF", color:"#FFFFFF", marginLeft:20, marginTop:5, opacity:0.6}} className={"betterButton flexCenter bender"}>
                                    What is this
                                    <img style={{width:20, marginLeft:5}} src={"./assets/infoIcon.PNG"} alt="infoIcon"/>
                                </button>
                                <div style={{color:"#FFFFFF", marginLeft:20, marginTop:5}}>
                                    <div className={"bender"} style={{margin:0}}>Game version : <span style={{color:"red"}}>13.0</span></div>
                                </div>
                            </div>
                        </div>
                        {this.state.userConnected && (
                            <div className={"flexCenter"} style={{flexWrap:"wrap", flexDirection:!this.state.showBrowserView && "column", marginLeft:!this.state.showBrowserView && "5%"}}>
                                <button onClick={()=>{this.setState({displayMode:"normal"})}} className={"betterButton bender"} style={{border:`1px solid ${this.state.displayMode === "normal" ? "green":"#FFFFFF"}`, borderRadius:10, color:this.state.displayMode === "normal" ? "green":"#FFFFFF", margin:5, fontSize:"1.17em"}}>Hideout & Quests</button>
                                <button onClick={()=>{this.setState({displayMode:"gunsmith"})}} className={"betterButton bender"} style={{border:`1px solid ${this.state.displayMode === "gunsmith" ? "green":"#FFFFFF"}`, borderRadius:10, color:this.state.displayMode === "gunsmith" ? "green":"#FFFFFF", margin:5, fontSize:"1.17em"}}>Gunsmith Suite</button>
                                {/*<button onClick={()=>{this.disconnectUser()}} className={"betterButton bender"} style={{margin:0, border:"1px solid #FFFFFF", borderRadius:10, color:"#FFFFFF", marginTop:10, fontSize:"1.17em"}}></button>*/}
                            </div>
                        )}
                        {this.state.userConnected && (
                            <form onSubmit={(e)=>{this.searchItems(e)}}>
                                <input className={"inputResearch bender"} type={"text"} placeholder={"Search"}></input>
                                <div style={{position:"relative", width:"100%",display:"flex", justifyContent:"flex-end"}}>
                                    <button type={"submit"} style={{position:"absolute", color:"#FFF", top:-40, right:5, backgroundColor:"transparent",fontSize:25, border:"none", cursor:"pointer"}}>
                                        {this.state.toSearch !== "" ? (
                                            <>❌</>
                                        ):(
                                            <>🔍</>
                                        )}
                                    </button>
                                </div>
                            </form>
                        )}
                        {this.state.userConnected ? (
                            <div className={"flexCenter"} style={{flexDirection:"column", marginRight:50}}>
                                <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                                    <img style={{width:30, marginRight:10}} src={"./assets/soldierIcon.PNG"} alt="userIcon"/>
                                    <h2 className={"bender"} style={{color:"#FFF", margin:0}}>{this.state.userConnected}</h2>
                                </div>
                                <button onClick={()=>{this.disconnectUser()}} className={"betterButton bender"} style={{margin:0, border:"1px solid red", borderRadius:10, color:"red", marginTop:10}}>Log off</button>
                            </div>
                        ):(
                            <div className={"flexCenter"} style={{marginRight:20}}>
                                <button onClick={()=>{this.setState({displayConnectionDialog:true})}} className={"betterButton bender"} style={{margin:0, border:"1px solid #FFF", borderRadius:10, fontSize:25, padding:5, display:"flex", alignItems:"center", justifyContent:"center", marginRight:10}}>
                                    Log in
                                    <img style={{width:58.5, marginLeft:0}} src={"./assets/soldierRunningIcon.PNG"} alt="userIcon"/>
                                </button>
                                <button onClick={()=>{this.setState({displayInscriptionDialog:true})}} className={"betterButton bender"} style={{margin:0, border:"1px solid #FFF", borderRadius:10, fontSize:25, padding:5, display:"flex", alignItems:"center", justifyContent:"center"}}>
                                    Sign up
                                    <img style={{width:30, marginLeft:10}} src={"./assets/soldierIcon.PNG"} alt="userIcon"/>
                                </button>
                            </div>
                        )}
                    </div>
                    {this.state.userConnected && (
                        <>
                            {this.state.displayMode === "normal" ? (
                                <>
                                    <div className={"flexCenter"} style={{width:"100%",height:50, flexDirection:"column"}}>
                                        <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                            <hr style={{width:"100%", height:1, backgroundColor:"#FFF",margin:0, display:"block"}}/>
                                        </div>
                                        <div style={{width:"100%",height:50, display:"flex", justifyContent:"space-evenly",alignItems:"center", whiteSpace:"nowrap"}}>
                                            <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>Items Checker :</h3>
                                            <button onClick={()=>{this.setState({displayHideoutChecker:true})}} className={"betterButton bender"} style={{border:"1px solid #FFFFFF", borderRadius:10, color:"#FFFFFF", margin:5, fontSize:"1.17em"}}>Hideout Checker</button>
                                            <button onClick={()=>{this.setState({displayQuestsChecker:true})}} className={"betterButton bender"} style={{border:"1px solid #FFFFFF", borderRadius:10, color:"#FFFFFF", margin:5, fontSize:"1.17em"}}>Quests Checker</button>

                                        </div>
                                    </div>
                                    <div className={"flexCenter"} style={{width:"100%",height:50, flexDirection:"column"}}>
                                        <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                            <hr style={{width:"100%", height:1, backgroundColor:"#FFF",margin:0, display:"block"}}/>
                                        </div>
                                        <div style={{width:"100%",height:50, display:"flex", justifyContent:"space-evenly",alignItems:"center", whiteSpace:"nowrap"}}>
                                            <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>Filters :</h3>
                                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>{this.state.showBrowserView ? "Display done items":"DDI" }:</h3>
                                                <h3 style={{color:"#FFF", marginRight:5}} className={"bender"}>OFF</h3>
                                                <div className={"flexCenter"}>
                                                    <label className="switch">
                                                        <input defaultChecked={true} onChange={()=>{this.setState({displayDone:!this.state.displayDone})}} value={this.state.displayDone} type="checkbox"/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <h3 style={{color:"#FFF", marginLeft:5, marginRight:5}} className={"bender"}>ON</h3>
                                                <Tooltip title={"If turned off, you will only see items that you are missing, whether in raid or not"}>
                                                    <img style={{width:20, opacity:0.8}} src={"./assets/infoIcon.PNG"} alt="infoIcon"/>
                                                </Tooltip>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>{this.state.showBrowserView ? "Display only remaining FIR":"DOR FIR"} :</h3>
                                                <h3 style={{color:"#FFF", marginRight:5}} className={"bender"}>OFF</h3>
                                                <div className={"flexCenter"}>
                                                    <label className="switch">
                                                        <input defaultChecked={false} onChange={()=>{this.setState({displayOnlyFir:!this.state.displayOnlyFir})}} value={this.state.displayOnlyFir} type="checkbox"/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <h3 style={{color:"#FFF", marginLeft:5, marginRight:5}} className={"bender"}>ON</h3>
                                                <Tooltip title={"If turned on, you will only see found in raid items that you lack"}>
                                                    <img style={{width:20, opacity:0.8}} src={"./assets/infoIcon.PNG"} alt="infoIcon"/>
                                                </Tooltip>
                                            </div>
                                            <div className={"flexCenter"} style={{width:"fit-content"}}>
                                                <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>{this.state.showBrowserView ? "Display only remaining NOR":"DOR NOR"} :</h3>
                                                <h3 style={{color:"#FFF", marginRight:5}} className={"bender"}>OFF</h3>
                                                <div className={"flexCenter"}>
                                                    <label className="switch">
                                                        <input defaultChecked={false} onChange={()=>{this.setState({displayOnlyNor:!this.state.displayOnlyNor})}} value={this.state.displayOnlyNor} type="checkbox"/>
                                                        <span className="slider round"></span>
                                                    </label>
                                                </div>
                                                <h3 style={{color:"#FFF", marginLeft:5,marginRight:5}} className={"bender"}>ON</h3>
                                                <Tooltip title={"If turned on, you will only see normal items that you lack"}>
                                                    <img style={{width:20, opacity:0.8}} src={"./assets/infoIcon.PNG"} alt="infoIcon"/>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ):(
                                <div className={"flexCenter"} style={{width:"100%",height:50, flexDirection:"column"}}>
                                    <div className={"flexCenter"} style={{width:"100%", flexDirection:"column"}}>
                                        <hr style={{width:"100%", height:1, backgroundColor:"#FFF",margin:0, display:"block"}}/>
                                    </div>
                                    <div style={{width:"100%",height:50, display:"flex", justifyContent:"space-evenly",alignItems:"center"}}>
                                        {/*<div className={"flexCenter"} style={{width:"fit-content"}}>*/}
                                        {/*    <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>Display type :</h3>*/}
                                        {/*    <h3 style={{color:"#FFF", marginRight:5}} className={"bender"}>All together</h3>*/}
                                        {/*    <div className={"flexCenter"}>*/}
                                        {/*        <label className="switch">*/}
                                        {/*            <input defaultChecked={false} onChange={()=>{this.setState({displayAllGunsmith:!this.state.displayAllGunsmith})}} value={this.state.displayAllGunsmith} type="checkbox"/>*/}
                                        {/*            <span className="slider round"></span>*/}
                                        {/*        </label>*/}
                                        {/*    </div>*/}
                                        {/*    <h3 style={{color:"#FFF", marginLeft:5, marginRight:5}} className={"bender"}>One by one</h3>*/}
                                        {/*    <Tooltip title={"All together means that all weapon parts will be displayed in only one tab, it can be usefull if a part is used in multiple gunsmith quests. One by one will display each quests separatly."}>*/}
                                        {/*        <img style={{width:20, opacity:0.8}} src={"./assets/infoIcon.PNG"} alt="infoIcon"/>*/}
                                        {/*    </Tooltip>*/}
                                        {/*</div>*/}
                                        <div onMouseLeave={()=>{this.setState({showMenuGunsmithParts:false})}} className={"flexCenter"} style={{whiteSpace:"nowrap"}}>
                                            <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>Filters :</h3>
                                            <button onClick={()=>{this.setState({showMenuGunsmithParts:!this.state.showMenuGunsmithParts})}} className={"betterButton flexCenter bender"} style={{border:"1px solid #FFFFFF", borderRadius:10, color:"#FFFFFF", margin:5, fontSize:"1.17em", width:230}}>Quest Filter </button>
                                            <div style={{position:"relative",width:"100%", direction:"rtl"}}>
                                                <div className={"divMenuPart"} style={{marginTop:15,transform:this.state.showMenuGunsmithParts ? "scaleY(1)":"scaleY(0)", zIndex:99999,display:"flex", alignItems:"flex-start", justifyContent:"space-evenly", flexWrap:"wrap"}}>
                                                    {[22,21,20,19,18,17,16,15,14,13,12,11,10,9,8,7,6,5,4,3,2,1].map(elt=>(
                                                        <>
                                                            <div style={{opacity:this.state[elt+"StateGun"] ? 1:0.4}} className={"borderButtonPart"}>
                                                                <button onClick={()=>{this.gunsmithPartManager(elt)}} className={"betterButton bender"} style={{margin:2, flex:"1 1 30px", color:"#232323"}} >Part {elt}</button>
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                </div>
                <div style={{display:"flex",flexDirection:"row", justifyContent:"flex-start",alignItems:"flex-start", justifyItems:"flex-start", alignContent:"flex-start", flexWrap:"wrap", width:"100%", height:"calc(100% - 150px)", overflowY:"auto"}}>

                    {!this.state.userConnected ? (
                        <div className={"flexCenter"} style={{width:"100%",height:"100%"}}>
                            <h1 className={"bender"} style={{color:"#FFF", fontWeight:100}}>LOG IN OR CREATE AN ACCOUNT TO USE THIS APP</h1>
                        </div>
                    ):(
                        <>
                            {this.state.displayMode === "normal" ? (
                                <>
                                    {this.state.items.length > 0 && (
                                        <>
                                            {this.state.items.map((item,index)=>(
                                                <div style={{display:((!this.state.displayDone && (item.myInvFir === item.fir && item.myInvNotFir === item.notfir)) || (this.state.displayOnlyNor && (item.myInvNotFir === item.notfir)) || (this.state.displayOnlyFir && (item.myInvFir === item.fir)) || (!item.name.includes(this.state.toSearch))) && "none"}} key={"item_"+index} className={"squareItem"}>
                                                    <div style={{position:"relative"}}>
                                                        <h2 className={"bender"} style={{fontWeight:100, margin:0, top:-27, left:-85, position:"absolute", whiteSpace:"nowrap"}}>{item.name}</h2>
                                                    </div>
                                                    <img className={"itemImage"} src={`../assets/${item.name}.PNG`} alt={item.name}/>
                                                    <div style={{position:"relative", width:"100%"}}>
                                                        <div style={{display:"flex", justifyContent:"space-evenly",alignItems:"center",width:"126%", height:50, backgroundColor:"rgba(0,0,0,0.7)", position:"absolute", left:-20, top:-26,borderRadius: "0px 0px 10px 10px"}}>
                                                            <div style={{width:"50%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", color:item.myInvFir === item.fir && "green"}}>
                                                                <div className={"bender"} style={{margin:0, display:"flex", alignItems:"center", justifyContent:"center"}}>
                                                                    <button style={{fontSize:21}} disabled={item.myInvFir === 0} onClick={()=>{this.updateItemsJson(item.name,"-","FIR")}} className={"betterButton"}>-</button>
                                                                    <h3 style={{margin:0}}>FIR</h3>
                                                                    <button style={{fontSize:21}} disabled={item.myInvFir === item.fir} onClick={()=>{this.updateItemsJson(item.name,"+","FIR")}} className={"betterButton"}>+</button>
                                                                </div>
                                                                <h3 style={{margin:0}}>{item.myInvFir}/{item.fir}</h3>
                                                            </div>
                                                            <div style={{width:"50%", display:"flex", alignItems:"center", justifyContent:"center", flexDirection:"column", color:item.myInvNotFir === item.notfir && "green"}}>
                                                                <div className={"bender"} style={{margin:0, display:"flex", alignItems:"center", justifyContent:"center"}}>
                                                                    <button style={{fontSize:21}} disabled={item.myInvNotFir === 0} onClick={()=>{this.updateItemsJson(item.name,"-","NOR")}} className={"betterButton"}>-</button>
                                                                    <h3 style={{margin:0}}>NOR</h3>
                                                                    <button style={{fontSize:21}} disabled={item.myInvNotFir === item.notfir} onClick={()=>{this.updateItemsJson(item.name,"+","NOR")}} className={"betterButton"}>+</button>
                                                                </div>
                                                                <h3 style={{margin:0}}>{item.myInvNotFir}/{item.notfir}</h3>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </>
                            ):(
                                <>
                                    {this.state.displayAllGunsmith ? (
                                        <>
                                            {this.state.itemsGunsmith.length > 0 && (
                                                <>
                                                    {this.state.itemsGunsmith.map((item,index)=>(
                                                        <div style={{display:((this.state.allGunsmithPartSelected.length !== 0 && !this.state.allGunsmithPartSelected.some(r=>item.part.includes(r))) || (!item.shortname.includes(this.state.toSearch) && !item.fullname.includes(this.state.toSearch))) && "none"}} key={"item_"+index} className={"squareItem"}>
                                                            <div style={{position:"relative", width:"100%"}}>
                                                                <div style={{display:"flex", justifyContent:"space-evenly", color:item.myInventorybyParts.length === item.part.length && "green",alignItems:"center",width:"126%", height:50, backgroundColor:"rgba(0,0,0,0.7)", position:"absolute", left:-20, top:-26,borderRadius: "10px 10px 0px 0px"}}>
                                                                    <h3 style={{margin:0}}>Part</h3>
                                                                    <div className={"flexCenter"}>
                                                                        {item.part.map((part,index)=>(
                                                                            <div className={"flexCenter"} style={{justifyContent:"space-between"}}>
                                                                                <h3 style={{margin:0, color:item.myInventorybyParts.includes(part) && "green"}}>{part}</h3>
                                                                                {index !== item.part.length -1 && (
                                                                                    <h3> - </h3>
                                                                                )}

                                                                            </div>
                                                                        ))}
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <img className={"itemImage"} style={{objectFit:"scale-down"}} src={`../assets/${item.shortname}.PNG`} alt={item.shortname}/>
                                                            <div style={{position:"relative", width:"100%"}}>
                                                                <div style={{display:"flex", justifyContent:"space-evenly", color:item.myInventorybyParts.length === item.part.length && "green",alignItems:"center",width:"126%", height:50, backgroundColor:"rgba(0,0,0,0.7)", position:"absolute", left:-20, top:-26,borderRadius: "0px 0px 10px 10px"}}>
                                                                    {/*<h3 style={{margin:0}}>NEED</h3>*/}
                                                                    <div className={"flexCenter"}>
                                                                        <button style={{fontSize:21}} disabled={item.myInventorybyParts.length === 0} onClick={()=>{this.updateGunsmithJson(item,"-")}} className={"betterButton"}>-</button>
                                                                        <h3 style={{margin:0}}>{item.myInventorybyParts.length}/{item.part.length}</h3>
                                                                        <button style={{fontSize:21}} disabled={item.myInventorybyParts.length === item.part.length} onClick={()=>{this.updateGunsmithJson(item,"+")}} className={"betterButton"}>+</button>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </>
                                            )}
                                        </>
                                    ):(
                                        <h3 className={"bender"} style={{color:"#FFF", margin:0, marginLeft:10, marginRight:10}}>GUNSMITH MODE</h3>
                                    )}
                                </>
                            )}
                        </>
                    )}


                </div>
            </div>
        );
    }
}

export default (DefaultPage)